var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RicePOSOrder-box px-RicePOSOrder-box",style:(`
    padding-top: ${_vm.configs.paddingTop}px;
  padding-right: ${_vm.configs.paddingRight}px;
  padding-bottom: ${_vm.configs.paddingBottom}px;
  padding-left: ${_vm.configs.paddingLeft}px;
  background-color:${_vm.configs.backgroundColor};
`),on:{"click":function($event){return _vm.clickItem('bgImage')}}},[_c('div',[_c('h1',{staticClass:"title",class:`edit_${_vm.Xindex}_title`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}title`] || _vm.configs.title))}}),_c('h2',{staticClass:"desc",class:`edit_${_vm.Xindex}_desc`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}desc`] || _vm.configs.desc))}})]),_c('div',{staticClass:"icon-image-box"},[_c('div',{staticClass:"icon-title-box"},[_c('div',{staticClass:"icon-title",style:(`text-align:${_vm.configs.textAline};`),domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}iconTitle`] || _vm.configs.iconTitle))}}),_c('div',{staticClass:"item-box"},_vm._l((_vm.configs.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('el-image',{staticClass:"icon",attrs:{"src":item.icon}}),_c('div',{staticClass:"icon-desc",domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(item[`${_vm.nowL}title`] || item.title))}})],1)}),0)]),_c('div',{staticClass:"image-box",style:(`margin-left1: ${_vm.configs.marginLeft}px;`)},[_c('el-image',{attrs:{"src":_vm.configs.image,"alt":_vm.configs.alt}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }