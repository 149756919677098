<!--
 * @Author: lzh
 * @Date: 2022-12-13 17:13:19
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 15:26:41
 * @Description: file content
-->
<template>
  <div
    class="RicePOSOrder-box px-RicePOSOrder-box"
    :style="`
      padding-top: ${configs.paddingTop}px;
    padding-right: ${configs.paddingRight}px;
    padding-bottom: ${configs.paddingBottom}px;
    padding-left: ${configs.paddingLeft}px;
    background-color:${configs.backgroundColor};
  `"
    @click="clickItem('bgImage')"
  >
    <div>
      <h1
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
        :class="`edit_${Xindex}_title`"
      ></h1>
      <h2
        class="desc"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
        :class="`edit_${Xindex}_desc`"
      ></h2>
    </div>
    <div class="icon-image-box">
      <div class="icon-title-box">
        <div
          class="icon-title"
          :style="`text-align:${configs.textAline};`"
          v-html="$util.rex.getHtml(configs[`${nowL}iconTitle`] || configs.iconTitle)"
        ></div>
        <div class="item-box">
          <div
            class="item"
            v-for="item,index in configs.items"
            :key="index"
          >
            <el-image
              class="icon"
              :src="item.icon"
            ></el-image>
            <div
              class="icon-desc"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="image-box"
        :style="`margin-left1: ${configs.marginLeft}px;`"
      >
        <el-image
          :src="configs.image"
          :alt="configs.alt"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "self-RicePOSOrder",
  mixins: [langMixin],
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
          title: "RicePOS",
          desc: "完善、強大的餐廳管理工具，快速完成點餐、結賬",
          iconDesc: "無論係中西意日韓，咖啡店定小食店，都可以適合嘅管理方案",
          image: require('../../assets/testImg/RicePOS-order.png'),
          marginLeft: 75,
          paddingTop: 74,
          paddingRight: 273,
          paddingBottom: 74,
          paddingLeft: 360,
          items: [
            {
              title: "中餐",
              icon: require('../../assets/testImg/lunchIcon.png'),
            },
            {
              title: "火鍋",
              icon: require('../../assets/testImg/hotPotIcon.png'),
            },
            {
              title: "小食",
              icon: require('../../assets/testImg/snackIcon.png'),
            },
            {
              title: "西餐",
              icon: require('../../assets/testImg/westernFoodIcon.png'),
            },
            {
              title: "咖啡",
              icon: require('../../assets/testImg/coffeeIcon.png'),
            },
            {
              title: "米線/拉麵",
              icon: require('../../assets/testImg/ramenNoodlesIcon.png'),
            },
          ]
        }
      }
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  data () {
    return {
      isEdit: false
    }
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },

    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  }
}
</script>

<style lang="less" scoped>
.RicePOSOrder-box {
  // min-width: 68vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 315px 60px !important;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
    text-align: center;
  }
  .icon-image-box {
    display: flex;
    align-items: center;
    margin-top: 66px;
    .icon-title-box {
      width: 20vw;
      min-width: 20vw;
      .icon-title {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .item-box {
        display: flex;
        flex-wrap: wrap;
        max-width: 210px;
        margin: 0 auto;
        justify-content: space-between;
        .item {
          width: calc(100% / 2);
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 50px;
          position: relative;
          .icon {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &:nth-child(2n + 1) {
            margin-left: 0;
          }
          &:nth-child(-n + 2) {
            margin-top: 35px;
          }

          &:nth-child(odd) {
            margin-left: 0;
          }
          .icon-desc {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            word-break: break-all;
            margin-top: 15px;
          }
        }
      }
    }
    .image-box {
      margin-left: 87px;
      /deep/ .el-image {
        width: 36vw;
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-RicePOSOrder-box {
    padding-left: 50px !important;
    padding-right: 50px !important;
    .title {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .icon-title-box {
      width: calc(50% - 5px) !important;
      min-width: calc(50% - 5px) !important;
      .item-box {
        width: 150px;
      }
    }
    .image-box {
      width: calc(50% - 5px) !important;
      min-width: calc(50% - 5px) !important;
      margin-left: 10px !important;
      .el-image {
        width: 100% !important;
      }
    }
    .icon-image-box {
      .icon-title-box {
        .icon-title {
          font-size: 14px !important;
        }
        .item-box {
          max-width: 100% !important;
          .item {
            .icon {
              width: 34px;
              height: 34px;
            }
            .icon-desc {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-RicePOSOrder-box {
    padding: 25px 22px !important;
    width: calc(100vw - 44px);
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .icon-image-box {
      flex: 1;
      margin-top: 11px;
      width: 100%;
      .icon-title-box {
        flex: 1;
        .icon-title {
          font-size: 10px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        .item-box {
          width: 100%;
          max-width: none;
          .item {
            margin-top: 10px;
            .icon {
              width: 20px;
              height: 20px;
            }
            .icon-desc {
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .image-box {
      width: calc(100% - 165px);
      margin-left: 15px !important;
      /deep/ .el-image {
        width: 100% !important;
      }
    }
  }
}
</style>